import React, { useState, useRef, useEffect } from "react"
import { Box, makeStyles } from "@material-ui/core"
import { DigicLink } from "../digic-link"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    width: "100%",
    height: "400px",
    [theme.breakpoints.up("sm")]: {
      height: "25vw",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40vw",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60vw",
    },
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(39, 62, 84, 1.0)",
    transition: "all 250ms ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(39, 62, 84, 0)",
      "& div": {
        transition: "all 250ms ease-in-out",
        backgroundColor: "#0000",
      },
    },
  },
  videoStyle: {
    position: "absolute",
    top: 0,
    width: "100%",
    aspectRatio: "16/9",
    objectFit: "cover",
    pointerEvents: "none",
    filter: "brightness(0.3)",
    transition: "filter 0.5s",
  },
  videoPlaying: {
    filter: "brightness(0.7)",
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
  },
}))

const HoverVideo = ({ image, title, videomp, videoweb, link, posterImage }) => {
  const classes = useStyles()

  const [isHovered, setIsHovered] = useState(false)
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current) {
      if (isHovered) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
      }
    }
  }, [isHovered])

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <DigicLink to={link}>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          width: "100%",
          aspectRatio: "16/9",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          margin: "auto",
        }}
      >
        <video
          ref={videoRef}
          loop
          muted
          poster={posterImage}
          className={`${classes.videoStyle} ${
            isHovered ? classes.videoPlaying : ""
          }`}
        >
          <source src={videomp} type="video/mp4" />
          <source src={videoweb} type="video/webm" />
        </video>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "25vw",
            height: "25vw",
          }}
        >
          <img src={image} alt={title} className={classes.logo} />
        </div>
      </Box>
    </DigicLink>
  )
}

export default HoverVideo
