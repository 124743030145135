import { Box, Button, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { DigicLink } from "../digic-link"
import AboutVideoMp4 from "../../videos/about.mp4"
import AboutVideoWebm from "../../videos/about.webm"
import AboutImage from "../../images/about.jpg"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    margin: "48px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  container: {
    marginTop: "128px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "48px",
    },
  },
  videoContainer: {
    position: "absolute",
    width: "100%",
    height: "55vw",
    transition: "all 800ms ease",
    [theme.breakpoints.down("sm")]: {
      height: "90vh",
    },
    minWidth: "100%",
    objectFit: "cover",
  },
}))

export const AboutUs = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        homePage {
          about_us
          about_us_title
          about_us_description
          about_us_image {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const classes = useStyles()

  const { about_us_title, about_us_description } = data.strapi.homePage
  return (
    <Box className={classes.container}>
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        poster={AboutImage}
        playsInline
        style={{
          filter: "brightness(40%)",
          width: "100%",
          marginBottom: "-12px",
          minHeight: "460px",
          maxHeight: "600px",
          objectFit: "cover",
        }}
      >
        <source src={AboutVideoMp4} type="video/mp4"></source>
        <source src={AboutVideoWebm} type="video/webm"></source>
      </video>
      <Box
        position="absolute"
        px={2}
        top={0}
        left={0}
        height="100%"
        width="100%"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          maxWidth="800px"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h1">{about_us_title}</Typography>
          <Typography
            style={{ textAlign: "center", margin: "32px 0" }}
            variant="body1"
          >
            {about_us_description}
          </Typography>
          <DigicLink
            to="/about"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <Button variant="outlined">Learn More</Button>
          </DigicLink>
        </Box>
      </Box>
    </Box>
  )
}
