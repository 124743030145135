import { makeStyles, Box, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FeaturedArticles } from "../components/article/featured-articles"
import { AboutUs } from "../components/homepage/aboutus"
import Layout from "../components/layout/layout-home"
import HoverVideo from "../components/utils/hover-video"
import productionsmp from "../videos/divisions/anotherhome.mp4"
import productionsweb from "../videos/divisions/anotherhome.webm"
import picturesmp from "../videos/divisions/pictures.mp4"
import picturesweb from "../videos/divisions/pictures.webm"
import servicesmp from "../videos/divisions/services.mp4"
import servicesweb from "../videos/divisions/services.webm"
import studiosmp from "../videos/divisions/studios.mp4"
import studiosweb from "../videos/divisions/studios.webm"
import Fade from "react-reveal/Fade"
import Hero from "../components/homepage/hero"
import PicturesPoster from "../videos/divisions/posters/pictures_poster.jpg"
import ProductionsPoster from "../videos/divisions/posters/productions_poster.jpg"
import ServicesPoster from "../videos/divisions/posters/services_poster.jpg"
import StudiosPoster from "../videos/divisions/posters/studios_poster.jpg"

const useStyles = makeStyles(theme => ({
  areaContainer: {
    display: "flex",
    width: "100%",
    height: "400px",
    [theme.breakpoints.up("sm")]: {
      height: "25vw",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40vw",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60vw",
    },
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(39, 62, 84, 1.0)",
    transition: "all 250ms ease-in-out",
    "& img": {
      transition: "all 250ms ease-in-out",
      filter: "drop-shadow(4px 4px 4px #000b)",
    },
    "& div": {
      transition: "all 250ms ease-in-out",
      backgroundColor: "#000b",
    },
    "&:hover": {
      backgroundColor: "rgba(39, 62, 84, 0)",
      "& img": {
        filter: "drop-shadow(8px 8px 8px #000b)",
        transform: "scale(1.01)",
      },
      "& div": {
        transition: "all 250ms ease-in-out",
        backgroundColor: "#0000",
      },
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "16px",
    margin: "64px 24px 128px 24px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      marginTop: "24px",
      marginBottom: "48px",
    },
  },
}))

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        articles(sort: "published_date:desc") {
          id
          title
          published_date
          thumbnail {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        homePage {
          id
          show_reel {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          our_business_areas
          business_area {
            title
            background_image {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 560) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title
            URL
            logo_image {
              url
            }
          }
          latest_news
          about_us
          hero_title_1
          hero_title_2
          hero_subtitle
          hero_primary_button_text
          hero_primary_button_link
          hero_secondary_button_text
          hero_secondary_button_link
        }
      }
    }
  `)

  const HomeData = data.strapi.homePage
  const classes = useStyles()

  const heroData = {
    title1: HomeData.hero_title_1,
    title2: HomeData.hero_title_2,
    subtitle: HomeData.hero_subtitle,
    primaryText: HomeData.hero_primary_button_text,
    primaryLink: HomeData.hero_primary_button_link,
    secondaryText: HomeData.hero_secondary_button_text,
    secondaryLink: HomeData.hero_secondary_button_link,
  }

  const divisions = [
    {
      logo: HomeData.business_area[0].logo_image.url,
      title: HomeData.business_area[0].title,
      videomp: picturesmp,
      videoweb: picturesweb,
      posterImage: PicturesPoster,
      link: HomeData.business_area[0].URL,
    },
    {
      logo: HomeData.business_area[1].logo_image.url,
      title: HomeData.business_area[1].title,
      videomp: servicesmp,
      videoweb: servicesweb,
      posterImage: ServicesPoster,
      link: HomeData.business_area[1].URL,
    },
    {
      logo: HomeData.business_area[2].logo_image.url,
      title: HomeData.business_area[2].title,
      videomp: studiosmp,
      videoweb: studiosweb,
      posterImage: StudiosPoster,
      link: HomeData.business_area[2].URL,
    },
    {
      logo: HomeData.business_area[3].logo_image.url,
      title: HomeData.business_area[3].title,
      videomp: productionsmp,
      videoweb: productionsweb,
      posterImage: ProductionsPoster,
      link: HomeData.business_area[3].URL,
    },
  ]

  return (
    <Layout>
      <Hero
        image={HomeData.show_reel.imageFile.childImageSharp.fluid}
        heroData={heroData}
      />
      <Fade>
        <Box maxWidth="1440px" margin="auto" id="service-areas">
          <Box margin="128px 24px 48px 24px">
            <Typography variant="h1">Our branches</Typography>
            <Typography variant="h3" color="primary">
              Learn more about our branches on their dedicated websites!
            </Typography>
          </Box>
          <Box className={classes.grid}>
            {divisions.map(division => (
              <HoverVideo
                image={division.logo}
                title={division.title}
                videomp={division.videomp}
                videoweb={division.videoweb}
                posterImage={division.posterImage}
                link={division.link}
              />
            ))}
          </Box>
        </Box>
        <Box maxWidth="1440px" margin="auto">
          <FeaturedArticles articles={data.strapi.articles} />
        </Box>
        <AboutUs />
      </Fade>
    </Layout>
  )
}

export default IndexPage
