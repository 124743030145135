import { makeStyles, Box, Typography, Button } from "@material-ui/core"
import React from "react"
import Fade from "react-reveal/Fade"
import { DigicLink } from "../digic-link"
import ScrollTo from "../utils/scroll-to"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  heroContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    paddingTop: "96px",
  },
  heroImage: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
  },
  heroTitle: {
    fontSize: "110px",
    lineHeight: "110px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "72px",
      lineHeight: "72px",
      maxWidth: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "46px",
      lineHeight: "46px",
    },
  },
  overlay: {
    position: "absolute",
    top: 48,
    left: 0,
    width: "100%",
    height: "100vh",
    background:
      "radial-gradient(circle at right, rgba(0, 0, 0, 0.2) 0%,rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 1) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%)",
    pointerEvents: "none",
    zIndex: "98",
  },
  buttonContainer: {
    display: "flex",
    gridGap: "24px",
    marginTop: "48px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  heroBodytext: {
    fontSize: "20px",
    marginTop: "16px",
    color: "#fafafa",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  link: {
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    width: "initial !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))

const Hero = ({ image, heroData }) => {
  const classes = useStyles()
  return (
    <Box className={classes.heroContainer}>
      <Box
        display="flex"
        position="absolute"
        zIndex={99}
        maxWidth="1440px"
        width="100%"
        padding="0 24px"
      >
        <Box maxWidth="760px">
          <Fade top duration={1400}>
            <Typography variant="h1" className={classes.heroTitle}>
              <span>{heroData.title1}</span>
              <br></br>
              <span>{heroData.title2}</span>
            </Typography>
          </Fade>
          <Fade top delay={400} duration={800}>
            <Typography variant="body1" className={classes.heroBodytext}>
              {heroData.subtitle}
            </Typography>
            <Box className={classes.buttonContainer}>
              <ScrollTo
                offset={-120}
                to={heroData.primaryLink}
                style={{ width: "100%" }}
              >
                <Button variant="contained" className={classes.button}>
                  {heroData.primaryText}
                </Button>
              </ScrollTo>
              <DigicLink to={heroData.secondaryLink} className={classes.link}>
                <Button variant="outlined" className={classes.button}>
                  {heroData.secondaryText}
                </Button>
              </DigicLink>
            </Box>
          </Fade>
        </Box>
      </Box>
      <Box className={classes.overlay} />
      <Fade duration={3000}>
        <Img fluid={image} className={classes.heroImage} loading="eager" />
      </Fade>
    </Box>
  )
}

export default Hero
